import { SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Modal } from 'antd';
import { KButton } from 'common/component/KButton';
import useDebounce from 'common/hooks/useDebounce';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import { ParentResponseModel } from 'data/parent/parent.model';
import usePagination from 'features/users/hooks/usePagination';
import { useUser } from 'features/users/hooks/useUser';
import ViewCard from 'features/users/view/components/ViewCard';
import AddCardForm from 'features/users/view/useCardForm';
import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import StudentDetails from '../modals/StudentDetails';
import { useSave } from 'features/users/hooks/useSave';

type ActionFunction = (row: ParentResponseModel) => void;

const parentsViewTable = (
  onAssignCard: ActionFunction,
  onViewCard: ActionFunction,
  openEdit: ActionFunction,
): TableColumn<ParentResponseModel>[] => [
  {
    name: 'Full name',
    cell: (row): string => row?.__user__.full_name || '',
    grow: 1,
    maxWidth: '500px',
  },
  {
    name: 'Email',
    cell: (row): JSX.Element => <>{row?.__user__.email || ''}</>,
  },
  {
    name: 'Card',
    cell: (row): JSX.Element => (
      <>
        {row?.__user__.card_number ? (
          <Button onClick={(): void => onViewCard(row)} type="link">
            View Card Details
          </Button>
        ) : null}
        <Button onClick={(): void => onAssignCard(row)} type="link">
          Assign Card
        </Button>
        <Button onClick={(): void => openEdit(row)} type="link">
          Edit
        </Button>
      </>
    ),
  },
];

const AddRole = (): JSX.Element => {
  const [modalHandler, setModalHandler] = React.useState<{
    modal: 'assign-card' | 'view-card' | 'view-student' | 'edit' | null;
    user: ParentResponseModel | null;
  }>({
    modal: null,
    user: null,
  });

  const [searchValue, setSearchValue] = React.useState<string>('');

  const { onParentEdit } = useSave();
  const [form] = Form.useForm();
  const parent = useSelector((state: RootState) => state.parent);

  const openAssignCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'assign-card',
      user: row,
    });
  };

  const openEdit: ActionFunction = (row) => {
    setModalHandler({
      modal: 'edit',
      user: row,
    });
  };

  const openViewCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'view-card',
      user: row,
    });
  };

  const openViewDetails: ActionFunction = (row) => {
    setModalHandler({
      modal: 'view-student',
      user: row,
    });
  };

  const closeModal = (): void => {
    setModalHandler({
      ...modalHandler,
      modal: null,
    });
  };

  //   const handleParentUpdate = async (parentId: any) => {
  //     const parentBody = { ...form.getFieldsValue() };
  //     // console.log({ parentBody });
  //     // await onParentEdit(parentId, parentBody, form, handleAfterMath);
  //   };

  const { fetchParent } = useUser();

  const db_search = useDebounce<string>(searchValue.toLowerCase(), 500);

  const searchFilteredParents = React.useMemo(() => {
    if (!db_search.length) return parent.data.data;

    return [...parent.data.data].filter(
      (item) => item?.__user__.full_name.toLowerCase().search(db_search) > -1,
    );
  }, [parent.data.data, db_search]);

  const filteredStudents = React.useMemo(() => {
    return [...searchFilteredParents].sort((a, b) => {
      // eslint-disable-next-line
      return a?.__user__.full_name < b?.__user__.full_name ? -1 : 1;
    });
  }, [searchFilteredParents]);

  const { handlePageChange, handlePerRowsChange, paginatedData, rowsPerPage } = usePagination(
    filteredStudents,
  );

  const reload = (): void => {
    fetchParent(1, 10000);
  };

  React.useEffect(() => {
    fetchParent(1, 10000);
    handlePageChange(1);
  }, []);

  const [selectedParent, setSelectedParent] = useState<any>(null);

  const handleParentEdit = (details: any): void => {
    setModalHandler({
      modal: 'edit',
      user: details,
    });

    setSelectedParent(details);

    form.setFieldsValue({
      full_name: details?.__user__?.full_name,
      email: details?.__user__?.email,
      phone_number: details?.__user__?.phone_number,
      address: details?.__user__?.address,
    });
  };

  const handleAfterMath = () => {
    setModalHandler({
      modal: null,
      user: null,
    });
    setSelectedParent(null);
    reload();
  };

  const handleSubmit = async () => {
    if (!selectedParent) return;

    return await onParentEdit(
      selectedParent?.__user__?.parentId,
      form.getFieldsValue(),
      form,
      handleAfterMath,
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <section style={{ marginRight: 35 }}>
            <h2 style={{ margin: 0 }}>Parents</h2>
          </section>
          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <Input
              placeholder="Search By Name"
              value={searchValue}
              onChange={(ev): void => setSearchValue(ev.target.value)}
              addonAfter={<SearchOutlined />}
            />
          </section>
        </div>
        <div className="relaod">
          <KButton onClick={reload}>Reload</KButton>
        </div>
      </div>
      <DataTable
        noHeader
        responsive
        pointerOnHover
        highlightOnHover
        pagination
        paginationServer
        customStyles={tableCustomStyles}
        data={paginatedData}
        columns={parentsViewTable(openAssignCard, openViewCard, handleParentEdit)}
        onRowClicked={openViewDetails}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={parent.data.totalCount}
        progressPending={parent.status === 'loading'}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
      {modalHandler.user ? (
        <>
          <Drawer
            destroyOnClose
            width={500}
            title="Assign Card"
            visible={modalHandler.modal === 'assign-card'}
            onClose={closeModal}
          >
            <AddCardForm
              onFinish={(): void => {
                closeModal();
                reload();
              }}
              user={modalHandler.user}
            />
          </Drawer>
          <Drawer
            width={500}
            title="View Card Details"
            visible={modalHandler.modal === 'view-card'}
            onClose={closeModal}
          >
            <ViewCard user={modalHandler.user} />
          </Drawer>
          <Drawer
            width={500}
            title="Edit Parent"
            visible={modalHandler.modal === 'edit'}
            onClose={closeModal}
          >
            <Form form={form} name="nest-messages" layout="vertical" size="large">
              <Form.Item label="Full Name" name={'full_name'}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name={'email'}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item label="Phone Number" name={'phone_number'}>
                <Input />
              </Form.Item> */}
              <Form.Item label="Address" name={'address'}>
                <Input />
              </Form.Item>

              <Form.Item style={{ marginTop: '40px' }}>
                <Button
                  htmlType="submit"
                  onClick={handleSubmit}
                  style={{
                    width: '100%',
                  }}
                >
                  UPDATE
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
          <Modal
            destroyOnClose
            width={750}
            visible={modalHandler.modal === 'view-student'}
            onCancel={closeModal}
            footer={null}
          >
            <StudentDetails type="parent" details={modalHandler.user} />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default AddRole;
