import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { MarkSheetWrapper } from './styles';
import { Results, TestTypeAndContributionPair } from 'data/result/result.model';
import { cleanGradeSection } from 'common/utils/functions';
import { getRangeDescAndRemarks } from 'features/result/utils/utils';

export interface SchoolMeta {
  name: string;
  location: string;
  sub__location: string;
}
export interface ExamMeta {
  exam__year: {
    bs: string;
    ad: string;
  };
}
export interface StudentMeta {
  name: string;
  dob: {
    bs: string;
    ad: string;
  };
  registration__no: string;
  symbol__no: string;
  class: string;
}

const nf = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

interface MarkSheetProps {
  metaSchool: {
    schoolName: string;
    principal: string;
    principalSignature: string;
    examCoordinator: string;
    examCoordinatorSignature: string;
    schoolLogo: string;
    schoolAddress: string;
  };
  issueDate: Date | string;
  studentName: string;
  terminalExamId: string;
  studentRoll: string;
  studentSection: string;
  examYearId: string;
  testsandContribution: Array<{
    id: string;
    name: string;
    contribution?: string;
  }>;

  records: Results;
}

export default function MarkSheet({
  issueDate,
  studentName,
  studentRoll,
  studentSection,
  terminalExamId,
  examYearId,
  testsandContribution,
  records,
  metaSchool,
}: MarkSheetProps) {
  return (
    <MarkSheetWrapper>
      <div className="wrapper">
        <div className="dates">
          <span className="printed">Printed On: {new Date().toDateString()}</span>
          <span className="printed">Issued On: {issueDate}</span>
        </div>

        <div className="container">
          <header>
            <img className="school__logo" src={metaSchool.schoolLogo} />
            <h1 className="school__name">{metaSchool.schoolName}</h1>
            <h2 className="school__location">{metaSchool.schoolAddress}</h2>
            <h3 className="school__sub__location">Province 1, Nepal</h3>
          </header>
          <main>
            <h1>Grade-Sheet</h1>
            <div className="declaration">
              <div className="line">
                <div className="label">The Grade(s) secured by:</div>
                <div className="value">{studentName}</div>
              </div>
              <div className="line">
                <div className="label">Exam</div>
                <div className="value">{terminalExamId?.split('|')[1]}</div>
                {/* <div className="label">(</div>
                <div className="value">01/11/2006</div>
                <div className="label">A.D. )</div> */}
              </div>
              <div className="line">
                {/* <div className="label">Registration No.:</div> */}
                {/* <div className="value"></div> */}
                <div className="label">Roll No.</div>
                <div className="value">{studentRoll}</div>
                <div className="label">Class:</div>
                <div className="value">{cleanGradeSection(studentSection)}</div>
              </div>
              <div className="line">
                <div className="label">in the annual examination conducted in:</div>
                <div className="value">{examYearId}</div>
                {/* <div className="label">B.S. (</div> */}
                {/* <div className="value">2022</div>
                <div className="label">A.D. )</div> */}
              </div>
              <div className="line">
                <div className="label">Are given below.</div>
              </div>
            </div>
            <div className="grade__table">
              <table>
                <thead>
                  <tr>
                    <td>S.N.</td>
                    {/* <td className="code">CODE</td> */}
                    <td className="subject">Subject</td>
                    <td className="marks">FM</td>
                    <td className="marks">PM</td>
                    {testsandContribution.map((test) => (
                      <td className="marks">
                        {test.name.slice(0, 2)} {test.contribution}
                      </td>
                    ))}
                    <td className="marks">OM</td>
                    <td className="marks">GPA</td>
                  </tr>
                </thead>

                <tbody>
                  {records.subjects.map((subject, index) => {
                    return (
                      <tr key={subject.subject}>
                        <td>{index + 1}</td>
                        <td className="subject">{subject.subject}</td>
                        <td className="marks">{subject.subResults[0].full_mark}</td>
                        <td className="marks">{subject.subResults[0].pass_mark}</td>
                        {subject.subResults.map((result) => {
                          return (
                            <td key={result.testTypeId} className="marks">
                              {result.obtained_mark}
                            </td>
                          );
                        })}
                        <td className="marks">{subject.totalOM}</td>
                        <td className="marks">{subject.overAllGpa}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <div className="footer">
                  <tr>
                    <td className="subject">Total</td>
                    <td className="marks">{records.totalFM}</td>
                    <td className="marks"></td>
                    {testsandContribution.map((test) => (
                      <td key={test.id} className="marks"></td>
                    ))}
                    <td className="marks">{records.totalOM}</td>
                    <td className="marks">{records.overAllGpa}</td>
                  </tr>
                </div>
              </table>
            </div>
          </main>

          <div className="result__remarks">
            <div className="f-container">
              <div className="f-container">
                <div>Percentage:</div>
                <div>
                  {records.percentage}
                  {records.position.toString() === '-' ? 'FAIL' : 'DISTINCTION'}
                </div>
              </div>
              <div className="f-container">
                <div>Position:</div>
                <div>{records.position}</div>
              </div>
            </div>
            <div className="f-container">
              <div className="f-container">
                <div>Attendance:</div>
                <div>...... / ......</div>
              </div>
            </div>
            <div className="f-container">
              <div className="f-container">
                <div>Remarks:</div>
                <div>Outstanding. We're proud of your results.</div>
              </div>
            </div>
          </div>

          <footer>
            <div className="signature">
              <img
                height={50}
                src="https://cdn.discordapp.com/attachments/792669461873688587/1134057387330916382/signature.png"
              />
              <div className="line" />
              {/* <div className="name">Ujwal Basnet</div> */}
              <div className="act">Principal</div>
            </div>
            <div className="signature">
              <img
                height={50}
                src="https://cdn.discordapp.com/attachments/792669461873688587/1134056387509485578/exam_coordinator.png"
              />
              <div className="line" />
              {/* <div className="name">Dipesh Bhattarai</div> */}
              <div className="act">Exam Coordinator</div>
            </div>

            <div className="signature" style={{ marginTop: '50px' }}>
              <div className="line" />
              {/* <div className="name">Dipesh Bhattarai</div> */}
              <div className="act">Class Teacher</div>
            </div>
          </footer>
        </div>
      </div>
    </MarkSheetWrapper>
  );
}
