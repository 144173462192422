import { SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Modal } from 'antd';
import { KButton } from 'common/component/KButton';
import useDebounce from 'common/hooks/useDebounce';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import usePagination from 'features/users/hooks/usePagination';
import { useUser } from 'features/users/hooks/useUser';
import ViewCard from 'features/users/view/components/ViewCard';
import AddCardForm from 'features/users/view/useCardForm';
import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import StudentDetails from '../modals/StudentDetails';
import KSpinner from 'common/component/KSpinner';
import { useSave } from 'features/users/hooks/useSave';
import { OtherStaffResponseModel } from 'data/otherStaff/otherStaff.model';

type ActionFunction = (row: OtherStaffResponseModel) => void;

const otherstaffsViewTable = (
  onAssignCard: ActionFunction,
  onViewCard: ActionFunction,
  handleOtherStaffEdit: ActionFunction,
): TableColumn<OtherStaffResponseModel>[] => [
  {
    name: 'Full name',
    cell: (row): string => row?.full_name || '',
    grow: 1,
    maxWidth: '500px',
  },
  {
    name: 'Email',
    cell: (row): JSX.Element => <>{row?.email || ''}</>,
  },
  {
    name: 'Card',
    cell: (row): JSX.Element => (
      <>
        {row?.card_number ? (
          <Button onClick={(): void => onViewCard(row)} type="link">
            View Card Details
          </Button>
        ) : null}
        <Button onClick={(): void => onAssignCard(row)} type="link">
          Assign Card
        </Button>
        <Button onClick={(): void => handleOtherStaffEdit(row)} type="link">
          Edit
        </Button>
      </>
    ),
  },
];

const OtherStaffs = (): JSX.Element => {
  const [selectedOtherStaff, setSelectedOtherStaff] = useState<any>(null);
  const [modalHandler, setModalHandler] = React.useState<{
    modal: 'assign-card' | 'view-card' | 'view-student' | 'edit' | null;
    user: OtherStaffResponseModel | null;
  }>({
    modal: null,
    user: null,
  });
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = React.useState<string>('');

  const otherstaffs = useSelector((state: RootState) => state.otherstaff);

  const { onOtherStaffEdit } = useSave();

  const openAssignCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'assign-card',
      user: row,
    });
  };

  const openViewCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'view-card',
      user: row,
    });
  };

  const openViewDetails: ActionFunction = (row) => {
    // console.log({ row });
    const newRow = {
      ...row,
      __user__: {
        ...row,
      },
    };
    setModalHandler({
      modal: 'view-student',
      user: newRow,
    });
  };

  const closeModal = (): void => {
    setModalHandler({
      ...modalHandler,
      modal: null,
    });
  };

  const handleAfterMath = () => {
    setModalHandler({
      modal: null,
      user: null,
    });
    setSelectedOtherStaff(null);
    reload();
  };
  const handleSubmit = async () => {
    if (!selectedOtherStaff) return;

    return await onOtherStaffEdit(
      selectedOtherStaff?.__user__?.otherstaffsId,
      form.getFieldsValue(),
      form,
      handleAfterMath,
    );
  };

  const { fetchOtherStaff } = useUser();

  const db_search = useDebounce<string>(searchValue.toLowerCase(), 500);

  const searchFilteredOtherStaffs = React.useMemo(() => {
    if (!db_search.length) return otherstaffs.data.data;

    return [...otherstaffs.data.data].filter(
      (item) => item?.full_name.toLowerCase().search(db_search) > -1,
    );
  }, [otherstaffs.data.data, db_search]);

  const filteredStudents = React.useMemo(() => {
    return [...searchFilteredOtherStaffs].sort((a, b) => {
      // eslint-disable-next-line
      return a?.full_name < b?.full_name ? -1 : 1;
    });
  }, [searchFilteredOtherStaffs]);

  const { handlePageChange, handlePerRowsChange, paginatedData, rowsPerPage } = usePagination(
    filteredStudents,
  );

  const reload = (): void => {
    fetchOtherStaff(1, 10000);
  };

  const handleOtherStaffEdit = (details: any): void => {
    setModalHandler({
      modal: 'edit',
      user: details,
    });

    setSelectedOtherStaff(details);

    form.setFieldsValue({
      full_name: details?.full_name,
      email: details?.email,
      phone_number: details?.phone_number,
      address: details?.address,
    });
  };

  React.useEffect(() => {
    fetchOtherStaff(1, 10000);
    handlePageChange(1);
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <section style={{ marginRight: 35 }}>
            <h2 style={{ margin: 0 }}>OtherStaffs</h2>
          </section>
          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <Input
              placeholder="Search By Name"
              value={searchValue}
              onChange={(ev): void => setSearchValue(ev.target.value)}
              addonAfter={<SearchOutlined />}
            />
          </section>
        </div>
        <div className="relaod">
          <KButton onClick={reload}>Reload</KButton>
        </div>
      </div>
      {otherstaffs?.data ? (
        <DataTable
          noHeader
          responsive
          pointerOnHover
          highlightOnHover
          pagination
          paginationServer
          customStyles={tableCustomStyles}
          data={paginatedData}
          columns={otherstaffsViewTable(openAssignCard, openViewCard, handleOtherStaffEdit)}
          onRowClicked={openViewDetails}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={otherstaffs.data.totalCount}
          progressPending={otherstaffs.status === 'loading'}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />
      ) : (
        <KSpinner />
      )}
      {modalHandler.user ? (
        <>
          <Drawer
            destroyOnClose
            width={500}
            title="Assign Card"
            visible={modalHandler.modal === 'assign-card'}
            onClose={closeModal}
          >
            <AddCardForm
              onFinish={(): void => {
                closeModal();
                reload();
              }}
              user={modalHandler.user as any}
            />
          </Drawer>
          <Drawer
            width={500}
            title="Edit OtherStaff"
            visible={modalHandler.modal === 'edit'}
            onClose={closeModal}
          >
            <Form form={form} name="nest-messages" layout="vertical" size="large">
              <Form.Item label="Full Name" name={'full_name'}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name={'email'}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item label="Phone Number" name={'phone_number'}>
                <Input />
              </Form.Item> */}
              <Form.Item label="Address" name={'address'}>
                <Input />
              </Form.Item>

              <Form.Item style={{ marginTop: '40px' }}>
                <Button
                  htmlType="submit"
                  onClick={handleSubmit}
                  style={{
                    width: '100%',
                  }}
                >
                  UPDATE
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
          <Drawer
            width={500}
            title="View Card Details"
            visible={modalHandler.modal === 'view-card'}
            onClose={closeModal}
          >
            <ViewCard user={modalHandler.user as any} />
          </Drawer>
          <Modal
            width={750}
            visible={modalHandler.modal === 'view-student'}
            onCancel={closeModal}
            footer={null}
          >
            <StudentDetails details={modalHandler.user as any} />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default OtherStaffs;
