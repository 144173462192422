import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { Prettify } from 'common/helper/typeHelper';
import { SectionResults, StudentDetails, Subject } from 'data/result/result.model';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import useTermResult from '../hooks/useTermResult';
import PrintBulkMarkSheets from './components/PrintBulkMarkSheets';
import GroupingTable, { Column } from './GroupingTable';
import PrintResults from './PrintResults';
import { InfoWrapper, TestInfo } from './result.style';
// import { results as Results } from '../data';
import Calendar from 'common/component/KCalendar';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import PrintBulk from './components/TestMarksheet/PrintBulk';
import FinalGroupingTable from './FinalGroupingTable';
import { Section } from 'data/grade/grade.model';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';

interface ViewFinalResultsProps {
  examYearId: string;
  finalExamId: string;
  classId: string;
}

const PrintWrapper = styled.section`
  section {
    width: 100%;
    overflow-x: auto;
  }

  .printHeader {
    .school__info {
      width: 100%;
      text-align: center;
    }

    display: none;
  }
  .test__info {
    margin-top: 10px;
    width: 100%;
    display: flex;
    gap: 2em;
  }

  @media print {
    padding: 1em;

    .printHeader {
      display: block;
    }

    .test__info {
      font-size: 12px;
    }

    table {
      height: 100%;

      thead {
        td {
          font-weight: 400;
        }
      }
    }
  }
`;

const nf = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

const ViewFinalResults = ({
  examYearId,
  classId,
  finalExamId,
}: ViewFinalResultsProps): JSX.Element => {
  const { fetchFinalAggregatedResultForView } = useTermResult();
  const [showPrint, setShowPrint] = React.useState<boolean>(false);
  const [showPrintMarkSheet, setShowPrintMarkSheet] = React.useState<boolean>(false);
  const [showPrintMarkSheetAggregate, setShowPrintMarkSheetAggregate] = React.useState<boolean>(
    false,
  );
  const { configData, fetchSchoolConfig, isLoading } = useSchoolConfig();

  const [issueDate, setIssueDate] = React.useState<string>(new Date().toDateString());

  const [data, setData] = useState<SectionResults[]>([]);
  const [subjects, setSubjcts] = useState<Subject[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [sectionsAndResults, setSectionsAndResults] = useState<
    Array<{
      name: string;
      results: SectionResults[];
    }>
  >([]);
  const [test_types_list, settest_types_list] = useState<Array<string>>([]);
  const [testAndContribution, settestAndContribution] = useState<
    Array<{
      id: string;
      name: string;
      contribution: string;
    }>
  >([]);

  const togglePrint = (): void => {
    setShowPrint(!showPrint);
  };

  const togglePrintMarkSheet = (): void => {
    setShowPrintMarkSheet(!showPrintMarkSheet);
  };

  const togglePrintMarkSheetAggregate = (): void => {
    setShowPrintMarkSheetAggregate(!showPrintMarkSheetAggregate);
  };

  const finalAggregatedResults = useSelector((state: RootState) => state.finalAggregatedResults);

  const examIdName = React.useMemo(() => {
    const tp = 'final'.length;
    const xm = examYearId.length;

    return finalExamId.slice(tp + xm + 2);
  }, [finalExamId, examYearId]);

  const printResultTypes = React.useMemo(() => {
    return (
      <>
        <Button type="link" onClick={togglePrint}>
          Print Ledger
        </Button>
        <Button type="link" onClick={togglePrintMarkSheet}>
          Print Marksheets
        </Button>
        <Button type="link" onClick={togglePrintMarkSheetAggregate}>
          Print Aggregates
        </Button>
      </>
    );
  }, []);

  const tableColumns = React.useMemo(() => {
    return [
      {
        id: 'studentRoll',
        label: 'Roll',
        maxWidth: 80,
      },
      {
        id: 'studentName',
        label: 'Student',
        align: 'left',
      },
      ...subjects.map((sub) => ({
        id: sub.subjectId,
        label: sub.subjectTitle,
        children: [
          ...testAndContribution.map((tt, index) => {
            return {
              id: tt.id,
              label: tt.name,
            };
          }),
          {
            id: 'total',
            label: 'Total',
          },
        ],
      })),
      {
        id: 'sum-total',
        label: 'Total',
      },
      {
        id: 'percent-total',
        label: '%',
        maxWidth: 80,
      },
      {
        id: 'position',
        label: 'Pos',
        maxWidth: 80,
      },
    ] as Column[];
  }, [subjects]);

  React.useEffect(() => {
    if (finalAggregatedResults.status === 'data' && !isLoading) {
      const tempSections = finalAggregatedResults.data.sections;
      const tempSubjects = finalAggregatedResults.data.subjects;
      const sectionsAndResults = tempSections;
      const test_types_list = finalAggregatedResults?.data?.terminalExamAndContributionPair.map(
        (terminal) => {
          return Object.keys(terminal)[0];
        },
      );
      const testAndContribution = finalAggregatedResults.data.terminalExamAndContributionPair.map(
        (terminal, index) => {
          const testId = Object.keys(terminal)[0];
          return {
            id: Object.keys(terminal)[0],
            name: Object.keys(terminal)[0].slice(0, 4),
            contribution: terminal[testId as keyof typeof terminal]?.toString(),
          };
        },
      );
      setSectionsAndResults(sectionsAndResults);
      settest_types_list(test_types_list);
      settestAndContribution(testAndContribution);
      setSections(
        finalAggregatedResults?.data.sections.map((sec) => {
          return { id: sec.name };
        }),
      );
      setSubjcts(tempSubjects);
      const tempResults = sectionsAndResults.map((sec) => [...sec.results]).flat(1);
      setData(tempResults);
    }
  }, [finalAggregatedResults]);

  React.useEffect(() => {
    if (configData) return;
    fetchSchoolConfig();
  }, []);
  React.useEffect(() => {
    fetchFinalAggregatedResultForView(examYearId, finalExamId, classId);
  }, [examYearId, classId, finalExamId]);

  return (
    <>
      <InfoWrapper>
        <div className="meta">
          <div className="info__item">
            <div className="info__title">Year</div>
            <div className="info__value">{examYearId}</div>
          </div>
          <div className="info__item">
            <div className="info__title">Exam</div>
            <div className="info__value">{examIdName}</div>
          </div>
          <div className="info__item">
            <div className="info__title">Class</div>
            <div className="info__value">Class {classId}</div>
          </div>
        </div>

        <div className="controls">
          <Calendar
            style={{ width: 250 }}
            language="en"
            placeholder="Select Date of Issue"
            onChange={({ bsDate }: any) => {
              setIssueDate(bsDate || null);
            }}
          />
          <Popover content={printResultTypes} trigger={['click']} placement="left">
            <Button>Print</Button>
          </Popover>
        </div>
      </InfoWrapper>

      <TestInfo>
        <div>
          {testAndContribution.map((test) => {
            return (
              <div>
                {test.name} {test.contribution}
              </div>
            );
          })}
        </div>
      </TestInfo>

      {sectionsAndResults?.map((section) => {
        return (
          <section>
            <FinalGroupingTable
              data={section.results}
              columns={tableColumns}
              tests={testAndContribution.map((test) => test.name)}
              loading={finalAggregatedResults.status === 'loading'}
            />
          </section>
        );
      })}

      {showPrint && issueDate ? (
        <PrintResults
          metaSchool={{
            principal: configData?.data.schoolDetails.principal,
            principalSignature: configData?.data.schoolDetails.principalSignature,
            examCoordinator: '',
            examCoordinatorSignature: '',
            schoolName: configData?.data.schoolDetails.schoolName,
            schoolLogo: configData?.data.schoolDetails.schoolLogo,
            schoolAddress: configData?.data.schoolDetails.schoolAddress,
          }}
          issueDate={issueDate}
          data={sectionsAndResults.map((section) => section.results)}
          columns={tableColumns}
          test_types={test_types_list}
          isLoading={finalAggregatedResults.status === 'loading'}
          meta={{
            classId,
            examYearId,
            examId: examIdName,
            sections: sections,
          }}
          onFinish={(): void => {
            setShowPrint(false);
          }}
        />
      ) : null}

      {showPrintMarkSheet && issueDate ? (
        <PrintBulk
          data={{
            metaSchool: {
              principal: '',
              principalSignature: configData?.data.schoolDetails.principal,
              examCoordinator: configData?.data.schoolDetails.principalSignature,
              examCoordinatorSignature: '',
              schoolName: configData?.data.schoolDetails.schoolName,
              schoolLogo: configData?.data.schoolDetails.schoolLogo,
              schoolAddress: configData?.data.schoolDetails.schoolAddress,
            },
            issueDate: issueDate,
            examYearId: examYearId,
            testExamId: finalExamId,
            sectionId: '',
            records: data.map((d) => {
              return {
                studentName: d.name,
                totalFM: d.results.totalFM.toString(),
                totalOM: d.results.totalOM.toString(),
                overAllGpa: d.results.overAllGpa.toString(),
                position: d.results.position.toString(),
                percentage: d.results?.percentage.toString(),
                remarks: d.results?.remarks,
                studentRoll: Number(d.results.position),
                subjects: d.results.subjects.map((sub) => {
                  return {
                    subjectId: sub.subjectId,
                    title: sub.subject,
                    full_mark: sub.totalFM.toString(),
                    pass_mark: '40',
                    obtained_mark: sub.totalOM.toString(),
                    gpa: sub.overAllGpa,
                  };
                }),
              };
            }),
          }}
          onFinish={(): void => {
            setShowPrintMarkSheet(false);
          }}
        />
      ) : null}

      {showPrintMarkSheetAggregate && issueDate ? (
        <PrintBulkMarkSheets
          metaSchool={{
            principal: '',
            principalSignature: 'string',
            examCoordinator: 'string',
            examCoordinatorSignature: '',
            schoolName: configData?.data.schoolDetails.schoolName,
            schoolLogo: configData?.data.schoolDetails.schoolLogo,
            schoolAddress: configData?.data.schoolDetails.schoolAddress,
          }}
          issueDate={issueDate}
          examYearId={examYearId}
          terminalExamId={finalExamId}
          records={data}
          testsandContribution={testAndContribution}
          onFinish={(): void => {
            setShowPrintMarkSheetAggregate(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ViewFinalResults;
