import { Form, Input } from 'antd';
import { ParentResponseModel } from 'data/parent/parent.model';
import { StudentResponseModel } from 'data/student/student.model';
import { TeacherResponseModel } from 'data/teacher/teacher.model';
import React from 'react';

interface ViewCardProps {
  user: StudentResponseModel | TeacherResponseModel | ParentResponseModel;
}

const ViewCard = ({ user }: ViewCardProps): JSX.Element => {
  return (
    <>
      <Form layout="vertical">
        <Form.Item label="Student">
          <Input readOnly value={user?.__user__.full_name || ''} />
        </Form.Item>
        <Form.Item label="Card No.">
          <Input readOnly value={user?.__user__.card_number || ''} />
        </Form.Item>
      </Form>
    </>
  );
};

export default ViewCard;
